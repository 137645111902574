import { Button, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Body from "../../../Analytics/Promotion/Body";
import BottomHeader from "../../../Analytics/Promotion/BottomHeader";
import AssignJob from "./AssignJob";
import CandidatesTable from "./CandidatesTable";
import GroupTable from "./GroupTable";

const DetailsTab = () => {
  const dispatch = useAppDispatch();
  const { jobDetailsData } = useAppSelector(
    (state: any) => state.getJobDetails
  );

  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [active, setActive] = useState<any>(false);
  const [switchTableMode, setSwitchTableMode] = useState("user");

  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  return (
    <div className="details-tab">
      <Tabs value={activeStep} onChange={handleChange} className="tabs">
        <Tab label="Candidates" />
        <Tab label="Description" />
        <Tab label="Questions" />
        <Tab label="Analytics" />

        {(userInfo?.roles?.includes("enterprise-admin") ||
          userInfo?.roles?.includes("recruiter-admin")) && (
          <Tab label="Permissions" />
        )}
      </Tabs>

      {activeStep === 1 && (
        <div className="description">
          {jobDetailsData?.description != "" ? (
            // <Typography variant="h6" component="p">
            //   <ReactMarkdown
            //     className="markdown-data"
            //     remarkPlugins={[remarkGfm]}
            //     rehypePlugins={[rehypeRaw]}
            //   >
            //     {jobDetailsData?.description}
            //   </ReactMarkdown>
            // </Typography>
            <div
              dangerouslySetInnerHTML={{ __html: jobDetailsData?.description }}
            />
          ) : (
            <div className="fallback">
              <img src="https://s1.ecnstatic.com/ecn/images/white binoculars.png" />
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#212121"
                }}
              >
                No Description found!
              </p>
            </div>
          )}
        </div>
      )}

      {activeStep === 2 && (
        <div className="question">
          {jobDetailsData?.job_questions &&
          jobDetailsData?.job_questions?.length === 0 ? (
            <div className="fallback">
              <img src="https://s1.ecnstatic.com/ecn/images/white binoculars.png" />
              <p>No results found!</p>
            </div>
          ) : (
            jobDetailsData?.job_questions?.map((item: any) => (
              <div className="ques">
                <h3>{item?.name}</h3>
                {item?.options &&
                  item?.options?.length != 0 &&
                  item?.options[0] != "" && <p>Options:</p>}
                <ul>{item?.options?.map((opt: any) => <li>{opt}</li>)}</ul>
              </div>
            ))
          )}
        </div>
      )}

      {activeStep === 0 && <CandidatesTable />}
      {activeStep === 3 && (
        <>
          <BottomHeader active={active} />
          <Body setActive={setActive} active={active} />
        </>
      )}
      {activeStep === 4 && (
        <div className="description-permission">
          {jobDetailsData?.groups?.length != 0 && (
            <div className="switch">
              <Button
                className={
                  switchTableMode === "user" ? "button active" : "button "
                }
                onClick={() => setSwitchTableMode("user")}
              >
                User
              </Button>
              <Button
                className={
                  switchTableMode === "group" ? "button active" : "button "
                }
                onClick={() => setSwitchTableMode("group")}
              >
                Groups
              </Button>
            </div>
          )}

          <AssignJob
            member={jobDetailsData?.members}
            groups={jobDetailsData?.groups}
            switchTableMode={switchTableMode}
          />
        </div>
      )}
    </div>
  );
};

export default DetailsTab;
