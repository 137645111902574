import {
  Autocomplete,
  Box,
  Button,
  Chip,
  MenuItem,
  Select
} from "@mui/material";
import { z } from "zod";
import layoutTwo from "../components/layout/layoutTwo";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";
import EcnInput from "../components/StyledComponents/V2/EcnInput";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect, useState } from "react";
import { getEventDetails } from "../redux/slices/EventSlices/getEventDetailsSlice";
import { Icon } from "@iconify/react";
import { getLocation } from "../redux/slices/JobSlices/getLocationSlice";
import { registerPrivateUser } from "../redux/slices/EventSlices/registerPrivateUserSlice";
import { enqueueSnackbar } from "notistack";
import { getEventAttendees } from "../redux/slices/EventSlices/getEventAttendeesSlice";
import { LoadingButton } from "@mui/lab";

const RegistrantSchema = z.object({
  name: z
    .string({ message: "Please enter your name" })
    .min(1, { message: "Please enter your name" }),
  email: z
    .string({ message: "Please enter email" })
    .email({ message: "Please enter a valid email" }),
  currentLevel: z
    .number({ message: "Plese select your current level" })
    .min(0)
    .max(2),
  location: z.object(
    {
      municipality: z.string(),
      countrySubdivision: z.string(),
      country: z.string()
    },
    { message: "Please select a location" }
  ),
  university: z.string().optional(),
  graduationYear: z.number().optional(),
  elevatePartnerClub: z.string().optional(),
  gpa: z.number().optional(),
  sat: z.number().optional(),
  act: z.number().optional(),
  gmat: z.number().optional(),
  jobTitle: z.string().optional(),
  jobFunction: z.string().optional(),
  organizationName: z.string().optional(),
  diversity: z.array(z.string()).default([]).optional()
});

const NewRegistrant = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);
  const { getLocationData } = useAppSelector(
    (state: any) => state.locationList
  );
  const {
    loading: loadingRegisterPrivateUser,
    error: errorRegisterPrivateUser
  } = useAppSelector((state) => state.registerPrivateUser);
  const [addressList, setAddressList] = useState<any>([]);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<z.infer<typeof RegistrantSchema>>({
    resolver: zodResolver(RegistrantSchema)
  });
  const [locationOpen, setLocationOpen] = useState(false);
  const [diversityOpen, setDiversityOpen] = useState(false);
  const [dispatched, setDispatched] = useState(false);
  const [loadingChanged, setLoadingChanged] = useState(false);

  const onSubmit = (data: z.infer<typeof RegistrantSchema>) => {
    const [first_name, last_name] = data.name.split(" ");
    const { act, gmat, sat, gpa } = data;
    dispatch(
      registerPrivateUser({
        event_id: eventId || "",
        basic_details: {
          first_name,
          last_name: last_name || "",
          email: data.email,
          diversity: data.diversity?.[0],
          partner_club: data.elevatePartnerClub
        },
        academic_scores: { act, gpa, sat, gmat },
        education: [
          {
            education_type: data.currentLevel,
            graduation_year: data.graduationYear
          }
        ],
        work_experiences: [{ city: data.location.municipality }]
      })
    );
    setDispatched(true);
  };

  useEffect(() => {
    if (eventId) {
      dispatch(getEventDetails({ eventId }));
    }
  }, [eventId]);

  useEffect(() => {
    if (getLocationData) {
      setAddressList(getLocationData);
    }
  }, [getLocationData]);

  useEffect(() => {
    setLoadingChanged(true);
  }, [loadingRegisterPrivateUser]);

  useEffect(() => {
    if (loadingChanged && dispatched) {
      if (!loadingRegisterPrivateUser && errorRegisterPrivateUser) {
        enqueueSnackbar("Unable to add registrant", { variant: "error" });
        setLoadingChanged(false);
        setDispatched(false);
      } else if (!loadingRegisterPrivateUser && eventId) {
        setDispatched(false);
        dispatch(getEventAttendees({ eventId }));
        enqueueSnackbar("Registrant added successfully!", {
          variant: "success"
        });
        navigate(`/event/${eventId || ""}/?tab=registrant-details`);
      }
    }
  }, [
    loadingChanged,
    loadingRegisterPrivateUser,
    errorRegisterPrivateUser,
    dispatched
  ]);

  const addressListHandler = (e: any) => {
    if (e !== null) dispatch(getLocation(e.target.value));
  };

  return (
    <div className="new-registrant-page">
      <EcnBreadCrumbs>
        <p onClick={() => navigate(`/events?tab=events`)}>Events</p>
        <p onClick={() => navigate(`/event/${eventId}/?tab=event-details`)}>
          {eventDetailsData?.name.slice(0, 25)}...
        </p>
        <p
          onClick={() => {
            navigate(`/event/${eventId}/?tab=registrant-details`);
          }}
        >
          Registrants
        </p>
        <p>New Registrant</p>
      </EcnBreadCrumbs>
      <div className="body">
        <h1 className="page-title">New Registrant</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field">
            <label htmlFor="name" className="field-label">
              Name*
            </label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <EcnInput
                  {...field}
                  placeholder="Enter Name"
                  className="field-input"
                />
              )}
            />
            <p className="error-message">{errors.name?.message}</p>
          </div>
          <div className="field">
            <label className="field-label">Email*</label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <EcnInput
                  {...field}
                  placeholder="Enter Email "
                  className="field-input"
                />
              )}
            />
            <p className="error-message">{errors.email?.message}</p>
          </div>
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 24 }}
          >
            <div className="field">
              <label className="field-label">Current Level*</label>
              <Controller
                name="currentLevel"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      className="select-input"
                      defaultValue={-1}
                      renderValue={(selected) => {
                        if (selected == -1) {
                          return (
                            <p className="select-placeholder">Select Level</p>
                          );
                        }
                        return (
                          <p>
                            {selected == 0
                              ? "Undergraduate"
                              : selected == 1
                                ? "MBA"
                                : "Other"}
                          </p>
                        );
                      }}
                      MenuProps={{
                        sx: {
                          ".MuiPaper-root": {
                            boxShadow:
                              "-7px 7px 40px 0px rgba(33, 33, 33, 0.10)",
                            border: "1px solid rgba(33,33,33,0.2)",
                            ".MuiList-root": {
                              ".MuiButtonBase-root": {
                                fontWeight: 400
                              }
                            }
                          },
                          ".MuiMenuItem-root": {
                            padding: "12px 14px",
                            borderRadius: "7px",
                            "&:hover": {
                              bgColor: "rgba(0, 52, 187, 0.05) !important",
                              color: "rgba(0, 52, 187, 1)"
                            }
                          }
                        }
                      }}
                      {...field}
                    >
                      <MenuItem value={-1} disabled>
                        Select Level
                      </MenuItem>
                      <MenuItem value={0}>Undergraduate</MenuItem>
                      <MenuItem value={1}>MBA</MenuItem>
                      <MenuItem value={2}>Other</MenuItem>
                    </Select>
                  );
                }}
              />
              <p className="error-message">{errors.currentLevel?.message}</p>
            </div>
            <div className="field">
              <label className="field-label">Location*</label>
              <Controller
                name="location"
                control={control}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      forcePopupIcon={false}
                      className="new-registrant-autocomplete"
                      disableClearable
                      onInputChange={(e) => addressListHandler(e)}
                      options={[
                        ...(customLocations[0]?.results ?? []),
                        ...(addressList?.results ?? [])
                      ]}
                      onOpen={() => {
                        setLocationOpen(true);
                      }}
                      onClose={() => {
                        setLocationOpen(false);
                      }}
                      onChange={(e, newValue) => {
                        field.onChange(newValue.address);
                      }}
                      getOptionLabel={(option: any) =>
                        option?.address
                          ? `${option.address.municipality}, ${option.address.countrySubdivision}, ${option.address.country}`
                          : ""
                      }
                      renderInput={(params) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <EcnInput
                              {...params}
                              placeholder="Enter job role"
                              className="auto-box"
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                top: "57%",
                                right: "13px", // Adjust this value as needed to position the arrow icon
                                transform:
                                  "translateY(-50%) rotate(" + "0deg" + ")",
                                transition: "transform 0.3s ease", // Add transition for smooth rotation
                                pointerEvents: "none"
                              }}
                            >
                              {locationOpen ? (
                                <Icon
                                  icon="lucide:chevron-up"
                                  width={24}
                                  height={24}
                                  color="rgba(33, 33, 33, 0.8)"
                                />
                              ) : (
                                <Icon
                                  icon="lucide:chevron-down"
                                  width={24}
                                  height={24}
                                  color="rgba(33, 33, 33, 0.8)"
                                />
                              )}
                            </Box>
                          </Box>
                        );
                      }}
                      renderOption={(props, option, { selected }) => (
                        <Box
                          component="li"
                          {...props}
                          sx={{
                            margin: "0px 10px",
                            marginBottom: "0px !important",
                            borderRadius: "8px !important",
                            padding: "10px 15px !important",
                            bgcolor: selected
                              ? "rgba(0, 52, 187, 0.05) !important"
                              : "transparent", // Apply the "info" color for selected options
                            "&:hover": {
                              bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                              color: "rgba(0, 52, 187, 1)"
                            }
                          }}
                        >
                          {`${option.address.municipality},
                ${option.address.countrySubdivision} ${
                  option.address.countrySubdivision === "" ? "" : ","
                } ${option.address.country}`}
                        </Box>
                      )}
                    />
                  );
                }}
              />
              <p className="error-message">{errors.location?.message}</p>
            </div>
          </div>
          <div
            style={{ display: "grid", gridTemplateColumns: "2fr 1fr", gap: 24 }}
          >
            <div className="field">
              <label className="field-label">University</label>
              <Controller
                name="university"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    placeholder="Enter your university"
                    {...field}
                    className="field-input"
                  />
                )}
              />
            </div>
            <div className="field">
              <label className="field-label">Graduation Year</label>
              <Controller
                name="graduationYear"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    forcePopupIcon={false}
                    clearIcon={false}
                    getOptionLabel={(option) => `${option}`}
                    onChange={(ev, val) => field.onChange(val)}
                    onInputChange={(e, val) => {
                      field.onChange(val);
                    }}
                    options={[...Array(12).keys()]
                      .map((a) => a - 9)
                      .map((num) => {
                        return new Date().getFullYear() + num;
                      })}
                    className="new-registrant-autocomplete"
                    renderInput={(params) => {
                      return (
                        <EcnInput
                          {...params}
                          type="number"
                          placeholder="Enter graduation year"
                        />
                      );
                    }}
                    renderOption={(props, option, { selected }) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{
                          margin: "0px 10px",
                          marginBottom: "0px !important",
                          borderRadius: "8px !important",
                          padding: "10px 15px !important",
                          bgcolor: selected
                            ? "rgba(0, 52, 187, 0.05) !important"
                            : "transparent", // Apply the "info" color for selected options
                          "&:hover": {
                            bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                            color: "rgba(0, 52, 187, 1)"
                          }
                        }}
                      >
                        {option}
                      </Box>
                    )}
                  />
                )}
              />
            </div>
          </div>
          <div className="field">
            <label className="field-label">Elevate Partner Club</label>
            <Controller
              name="elevatePartnerClub"
              control={control}
              render={({ field }) => (
                <EcnInput
                  {...field}
                  placeholder="Club Name"
                  className="field-input"
                />
              )}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 24
            }}
          >
            <div className="field">
              <label className="field-label">GPA</label>
              <Controller
                name="gpa"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    {...field}
                    value={field.value || ""}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (value !== "" && Number(ev.target.value) >= 0) {
                        field.onChange(Number(ev.target.value));
                      } else {
                        field.onChange(undefined);
                      }
                    }}
                    placeholder="Enter GPA"
                    className="field-input"
                    type="number"
                  />
                )}
              />
            </div>
            <div className="field">
              <label className="field-label">SAT</label>
              <Controller
                name="sat"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    {...field}
                    value={field.value || ""}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (value !== "" && Number(ev.target.value) >= 0) {
                        field.onChange(Number(ev.target.value));
                      } else {
                        field.onChange(undefined);
                      }
                    }}
                    placeholder="Enter SAT"
                    className="field-input"
                    type="number"
                  />
                )}
              />
            </div>
            <div className="field">
              <label className="field-label">ACT</label>
              <Controller
                name="act"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    {...field}
                    value={field.value || ""}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (value !== "" && Number(ev.target.value) >= 0) {
                        field.onChange(Number(ev.target.value));
                      } else {
                        field.onChange(undefined);
                      }
                    }}
                    placeholder="Enter ACT"
                    className="field-input"
                    type="number"
                  />
                )}
              />
            </div>
            <div className="field">
              <label className="field-label">GMAT</label>
              <Controller
                name="gmat"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    {...field}
                    value={field.value || ""}
                    onChange={(ev) => {
                      const value = ev.target.value;
                      if (value !== "" && Number(ev.target.value) >= 0) {
                        field.onChange(Number(ev.target.value));
                      } else {
                        field.onChange(undefined);
                      }
                    }}
                    placeholder="Enter GMAT"
                    className="field-input"
                    type="number"
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{ display: "grid", gridTemplateColumns: "2fr 1fr", gap: 24 }}
          >
            <div className="field">
              <label className="field-label">Job Title</label>
              <Controller
                name="jobTitle"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    {...field}
                    placeholder="Enter job title"
                    className="field-input"
                  />
                )}
              />
            </div>
            <div className="field">
              <label className="field-label">Job Function</label>
              <Controller
                name="jobFunction"
                control={control}
                render={({ field }) => (
                  <EcnInput
                    {...field}
                    placeholder="Enter job function"
                    className="field-input"
                  />
                )}
              />
            </div>
          </div>
          <div className="field">
            <label className="field-label">Organization Name</label>
            <Controller
              name="organizationName"
              control={control}
              render={({ field }) => (
                <EcnInput
                  {...field}
                  placeholder="Enter organization name"
                  className="field-input"
                />
              )}
            />
          </div>
          <div className="field">
            <label className="field-label">Diversity</label>
            <Controller
              name="diversity"
              control={control}
              render={({ field }) => {
                return (
                  <Autocomplete
                    className="new-registrant-autocomplete"
                    multiple
                    forcePopupIcon={false}
                    disableClearable
                    onOpen={() => setDiversityOpen(true)}
                    onClose={() => setDiversityOpen(false)}
                    {...field}
                    value={field.value}
                    onChange={(e, value) => field.onChange(value)}
                    options={diversity}
                    getOptionLabel={(diversity: any) => diversity}
                    renderInput={(params) => (
                      <Box sx={{ position: "relative" }}>
                        <EcnInput
                          {...params}
                          placeholder="Enter Location"
                          className="auto-box"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: "57%",
                            right: "13px", // Adjust this value as needed to position the arrow icon
                            transform:
                              "translateY(-50%) rotate(" + "0deg" + ")",
                            transition: "transform 0.3s ease", // Add transition for smooth rotation
                            pointerEvents: "none"
                          }}
                        >
                          {diversityOpen ? (
                            <Icon
                              icon="lucide:chevron-up"
                              width={24}
                              height={24}
                              color="rgba(33, 33, 33, 0.8)"
                            />
                          ) : (
                            <Icon
                              icon="lucide:chevron-down"
                              width={24}
                              height={24}
                              color="rgba(33, 33, 33, 0.8)"
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                    renderOption={(props, option, { selected }) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{
                          margin: "0px 10px",
                          marginBottom: "4px !important",
                          borderRadius: "8px !important",
                          padding: "10px 15px !important",
                          bgcolor: selected
                            ? "rgba(0, 52, 187, 0.05) !important"
                            : "transparent", // Apply the "info" color for selected options
                          "&:hover": {
                            bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                            color: "rgba(0, 52, 187, 1)"
                          },
                          color: field.value?.includes(option)
                            ? "rgba(0, 52, 187, 1)"
                            : ""
                        }}
                      >
                        {option}
                      </Box>
                    )}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          sx={{
                            bgcolor: "rgba(0, 52, 187, 0.8)",
                            color: "#fff",
                            marginLeft: "0 !important",
                            marginRight: "6px !important",
                            "& .MuiChip-deleteIcon": {
                              color: "#fff",

                              "&:hover": {
                                color: "rgba(255, 255, 255, 0.8)"
                              }
                            }
                          }}
                        />
                      ));
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="buttons-wrapper">
            <LoadingButton
              loading={loadingRegisterPrivateUser}
              loadingPosition="end"
              type="submit"
              variant="contained"
              endIcon={
                loadingRegisterPrivateUser && <span style={{ width: 16 }} />
              }
            >
              Add Registrant
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const customLocations: any = [
  {
    results: [
      {
        type: "Geography",
        id: "custom1",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "New York",
          countrySubdivision: "NY",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom2",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "Chicago",
          countrySubdivision: "IL",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom3",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "San Francisco",
          countrySubdivision: "CA",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom4",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "London",
          countrySubdivision: "",
          country: "UK"
        }
      },
      {
        type: "Geography",
        id: "custom5",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "Hong Kong",
          countrySubdivision: "",
          country: "China"
        }
      }
    ]
  }
];
const diversity = [
  "Women",
  "Black",
  "Latin",
  "Asian",
  "Multi-Racial",
  "LGBTQ+",
  "Disability",
  "Other Diversity",
  "None of the Above",
  "Prefer not to say"
];
export default layoutTwo(NewRegistrant);
