import completeOnboardingSlice from "./completeOnboardingSlice";
import getAiCreditsSlice from "./getAiCreditsSlice";
import getTopPickStatusSlice from "./getTopPickStatusSlice";
import getTopPicksSlice from "./getTopPicksSlice";
import getUnitsDataSlice from "./getUnitsDataSlice";
import postAiReviewSlice from "./postAiReviewSlice";
import getDashboardHomeSlice from "./getDashboardHomeSlice";

export const allDashboardSlices = {
  getUnitsData: getUnitsDataSlice,
  getAiCredits: getAiCreditsSlice,
  getTopPickStatus: getTopPickStatusSlice,
  postAiReview: postAiReviewSlice,
  getTopPicks: getTopPicksSlice,
  completeOnboarding: completeOnboardingSlice,
  getDashboardHomeData: getDashboardHomeSlice
};
