import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const postFilterJob = createAsyncThunk(
  "postFilterJob",
  async (filterData: any) => {
    try {
      const { data } = await axios.post(
        `${
          GATEWAY_BASE + JOB_CREATE_BASE
        }/api/v1/dashboard/get_filtered_jobs?skip=${
          filterData.skip ?? 0
        }&limit=${filterData.limit ?? 10}`,
        filterData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const postFilterJobSlice = createSlice({
  name: "postFilterJob",
  initialState: {
    loading: false,
    filterJobData: null,
    error: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postFilterJob.pending, (state, action) => {
      state.loading = true;
      // state.filterJobData = null;
    });
    builder.addCase(postFilterJob.fulfilled, (state, action) => {
      state.loading = false;
      state.filterJobData = action.payload;
    });
    builder.addCase(postFilterJob.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  }
});

export default postFilterJobSlice.reducer;
