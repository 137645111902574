import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useSearchParams } from "react-router-dom";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const SearchBar: React.FC<{
  searchText: any;
  setSearchText: any;
  setRow: any;
  rows: any;
  searchRow: any;
  selection?: any;
}> = ({ searchText, setRow, rows, searchRow, setSearchText, selection }) => {
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    setRow(rows);
    if (searchText === "") {
      setRow(searchRow);
    }
  }, [rows]);

  useEffect(() => {
    if (searchText != "") {
      const buttons = document.querySelectorAll(
        'button[aria-label="Show filters"]'
      );
      const buttons2 = document.querySelectorAll('button[aria-label="Export"]');
      buttons.forEach((button) => {
        button.classList.add("new-class");
      });
      buttons2.forEach((button) => {
        button.classList.add("new-class");
      });
    }
  }, [searchText]);

  const SarchHandler = (e: any) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const filteredRows = searchRow?.filter((row: any) => {
      return Object.keys(row)?.some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRow(filteredRows);
  };
  const clearSearch = () => {
    setSearchText("");
    setRow(searchRow);
  };

  const handleInputFocus = () => {
    const buttons = document.querySelectorAll(
      'button[aria-label="Show filters"]'
    );
    const buttons2 = document.querySelectorAll('button[aria-label="Export"]');
    buttons.forEach((button) => {
      button.classList.add("new-class");
    });
    buttons2.forEach((button) => {
      button.classList.add("new-class");
    });
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    if (searchText === "") {
      const buttons = document.querySelectorAll(
        'button[aria-label="Show filters"]'
      );
      const buttons2 = document.querySelectorAll('button[aria-label="Export"]');
      buttons.forEach((button) => {
        button.classList.remove("new-class");
      });
      buttons2.forEach((button) => {
        button.classList.remove("new-class");
      });
    }
    setInputFocused(false);
  };

  return (
    <div className="search-bar">
      <div
        className={
          inputFocused || searchText != ""
            ? `search active ${selection?.length != 0 ? "search-width  active-width":""}`
            : `search ${selection?.length != 0 ? "search-width":""}`
        }
      >
        <Icon icon="ri:search-line" width={23} height={23} color="grey" />
        <input
          type="text"
          onChange={SarchHandler}
          value={searchText}
          placeholder={"Search"}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {searchText != "" && (
          <Icon
            icon="basil:cross-outline"
            width={40}
            height={25}
            className="cross"
            color="grey"
            onClick={clearSearch}
          />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
