import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getEventAttendees } from "../../redux/slices/EventSlices/getEventAttendeesSlice";
import { useQueryParams } from "../../helpers/HOC/useQueryParams";

import { useParams } from "react-router-dom";

import { Avatar, Button, Chip, MenuItem, Stack } from "@mui/material";

import EcnStyledDataGridUpdated from "../StyledComponents/EcnStyledDataGridUpdated";
import RegistrantSearchFilter from "./Sub/RegistrantSearchFilter";
import moment from "moment";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import {
  updatePaymentStatus,
  PaymentStatusType
} from "../../redux/slices/EventSlices/updatePaymentStatus";
import { enqueueSnackbar } from "notistack";
import EcnSelect from "../StyledComponents/V2/EcnSelect";
import { bulkCertificateIssue } from "../../redux/slices/EventSlices/bulkCertificateIssueSlice";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500"
};

const PaymentsTab = () => {
  const dispatch = useAppDispatch();
  const [searchParams, _] = useQueryParams();
  const { eventId } = useParams();
  const search = searchParams.get("q");

  const { eventAttendeesData } = useAppSelector(
    (state) => state.getEventAttendees
  );
  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);
  const {
    loading: updatePaymentStatusLoading,
    error: updatePaymentStatusError
  } = useAppSelector((state) => state.updatePaymentStatus);
  const {
    loading: bulkCertificateIssueLoading,
    error: bulkCertificateIssueError
  } = useAppSelector((state) => state.bulkCertificateIssue);

  const [updatePaymentstatusDispatched, setUpdatePaymentstatusDispatched] =
    useState(false);
  const [loadingChanged, setLoadingChanged] = useState(false);

  const [bulkCertificateIssueDispatched, setBulkCertificateIssueDispatched] =
    useState(false);
  const [
    bulkCertificateIssueLoadingChanged,
    setBulkCertificateIssueLoadingChanged
  ] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState<Array<any>>(eventAttendeesData || []);

  useEffect(() => {
    if (eventId) {
      dispatch(getEventAttendees({ eventId, q: search }));
    }
  }, [eventId, search]);

  useEffect(() => {
    setRows(eventAttendeesData || []);
  }, [eventAttendeesData]);

  useEffect(() => {
    if (
      updatePaymentstatusDispatched &&
      loadingChanged &&
      !updatePaymentStatusLoading
    ) {
      if (!updatePaymentStatusError) {
        enqueueSnackbar("Payment status updated", { variant: "success" });
        const newRows = rows.map((row) => {
          const match = selectedRows.find(
            (selectedRow) => selectedRow == row._id
          );
          if (match) {
            return { ...row, payment_status: "Paid", payment_mode: "manual" };
          }
          return row;
        });
        setRows(newRows);
      } else {
        enqueueSnackbar("Unable to update payment status", {
          variant: "error"
        });
      }
      setLoadingChanged(false);
      setUpdatePaymentstatusDispatched(false);
    }
  }, [
    loadingChanged,
    updatePaymentStatusLoading,
    updatePaymentstatusDispatched
  ]);

  useEffect(() => {
    if (
      bulkCertificateIssueDispatched &&
      bulkCertificateIssueLoadingChanged &&
      !bulkCertificateIssueLoading
    ) {
      if (!bulkCertificateIssueError) {
        enqueueSnackbar("Certificate issued", { variant: "success" });
        const newRows = rows.map((row) => {
          const match = selectedRows.find(
            (selectedRow) => selectedRow == row._id
          );
          if (match) {
            return {
              ...row,
              issued_certificates: [...row.issued_certificates, { id: 1 }]
            };
          }
          return row;
        });
        setRows(newRows);
      } else {
        enqueueSnackbar("Unable to issue certificate", {
          variant: "error"
        });
      }
      setLoadingChanged(false);
      setUpdatePaymentstatusDispatched(false);
    }
  }, [
    bulkCertificateIssueLoadingChanged,
    bulkCertificateIssueLoading,
    bulkCertificateIssueDispatched
  ]);

  useEffect(() => {
    setLoadingChanged(true);
  }, [updatePaymentStatusLoading]);

  useEffect(() => {
    setBulkCertificateIssueLoadingChanged(true);
  }, [bulkCertificateIssueLoading]);

  const columns: any = useMemo(() => {
    const baseColumns = [
      {
        field: "name",
        headerName: "Candidate name",
        width: 170,
        flex: 1,
        renderHeader: () => (
          <Stack direction="row" alignItems="left">
            <p style={headerTextStyle}>Candidate name</p>
          </Stack>
        ),
        renderCell: (params: any) => (
          <Stack direction="row" spacing={1}>
            <>
              <div
                style={{ display: "flex", alignItems: "center", gap: "16px" }}
              >
                {params.row?.created_by.display_pictures?.length != 0 &&
                params.row?.created_by.display_pictures != null ? (
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    src={params?.row?.created_by.display_pictures[0]}
                  />
                ) : (
                  <div className="avatar-icon-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      {/* SVG path */}
                    </svg>
                  </div>
                )}
                <p className="candidate-name-text">
                  {params?.row?.created_by.first_name +
                    " " +
                    params?.row?.created_by.last_name}
                </p>
              </div>
            </>
          </Stack>
        )
      },
      {
        field: "timestamp",
        width: 210,
        flex: 1,
        renderHeader: () => (
          <Stack direction="row" alignItems="left">
            <p style={headerTextStyle}>Date of Registration</p>
          </Stack>
        ),
        renderCell: (params: any) => (
          <p>{moment.unix(params.row.timestamp).format("ll")}</p>
        )
      },
      {
        field: "issued_certificates",
        width: 200,
        flex: 1,
        renderHeader: () => (
          <Stack direction="row" alignItems="left">
            <p style={headerTextStyle}>Certificate Status</p>
          </Stack>
        ),
        renderCell: (params: any) => (
          <Stack direction="row" spacing={1}>
            {params?.row?.issued_certificates &&
            params?.row?.issued_certificates.length > 0 ? (
              <p>Issued</p>
            ) : (
              <Stack direction="row" spacing={6}>
                <p>Not Issued</p>
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    dispatch(
                      bulkCertificateIssue({
                        eventId: eventId || "",
                        registrationIds: [params?.row?.id as string]
                      })
                    );
                    setBulkCertificateIssueDispatched(true);
                  }}
                >
                  Issue
                </button>
              </Stack>
            )}
          </Stack>
        )
      }
    ];

    // Conditionally add last two columns if `paid_event` is true
    const paidEventColumns = eventDetailsData?.paid_event
      ? [
          {
            field: "payment_status",
            width: 180,
            flex: 1,
            renderHeader: () => (
              <Stack direction="row" alignItems="left">
                <p style={headerTextStyle}>Payment Status</p>
              </Stack>
            ),
            renderCell: (params: any) => (
              <EcnSelect
                key={params.row.payment_status}
                className="select-payment-status"
                defaultValue={
                  params.row.payment_status
                    ? (params.row.payment_status as string)
                    : undefined
                }
                onChange={(ev) => {
                  dispatch(
                    updatePaymentStatus({
                      event_id: params.row.event_id as string,
                      reg_ids: [params.row._id] as Array<string>,
                      payment_status: ev.target.value as PaymentStatusType
                    })
                  );
                  setUpdatePaymentstatusDispatched(true);
                }}
              >
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="Due">Due</MenuItem>
              </EcnSelect>
            )
          },
          {
            field: "payment_mode",
            width: 150,
            renderHeader: () => (
              <Stack direction="row" alignItems="left">
                <p style={headerTextStyle}>Payment Mode</p>
              </Stack>
            ),
            renderCell: (params: any) => {
              return (
                <Stack direction="row">
                  <p>{params?.row?.payment_mode || "manual"}</p>
                </Stack>
              );
            }
          }
        ]
      : [];

    return [...baseColumns, ...paidEventColumns];
  }, [eventDetailsData, dispatch, setUpdatePaymentstatusDispatched]);

  const selectionModelHandler = (newSelection: any) => {
    setSelectedRows(newSelection);
  };

  const handleMarkAsPaid = () => {
    dispatch(
      updatePaymentStatus({
        event_id: eventId || "",
        reg_ids: selectedRows as string[],
        payment_status: "Paid"
      })
    );

    setUpdatePaymentstatusDispatched(true);
  };

  const handleIssueCertificate = () => {
    dispatch(
      bulkCertificateIssue({
        eventId: eventId || "",
        registrationIds: selectedRows as string[]
      })
    );

    setBulkCertificateIssueDispatched(true);
  };

  return (
    <div className="payments-tab">
      <RegistrantSearchFilter paymentView />

      <div className="table-wrapper">
        <EcnStyledDataGridUpdated
          disableColumnMenu
          columns={columns}
          className="payments-table"
          rows={rows}
          onRowSelectionModelChange={selectionModelHandler}
          getRowId={(row: any) => row._id}
          slots={{
            baseCheckbox: (props: any) => <EcnCheckboxFilled {...props} />
          }}
        />
        {selectedRows.length > 0 && (
          <div className="selection-action-buttons">
            <Button onClick={handleMarkAsPaid} variant="contained">
              Mark as Paid
            </Button>
            <Button onClick={handleIssueCertificate} variant="contained">
              Issue Certificate
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentsTab;
