import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const candidatesList = createAsyncThunk(
  "candidatesList",
  async (id: any) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/list/${
        id?.id
      }?skip=${id?.skip ? id.skip : 0}&limit=${id.limit ?? "9999"}${
        id?.st === -1 ? "" : "&status=" + id?.st
      }${id.sort ? "&sort=" + id.sort : ""}`
    );

    return data;
  }
);

const candidatesListSlice = createSlice({
  name: "candidatesList",
  initialState: {
    loading: false,
    candidatesListData: null,
    error: false,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(candidatesList.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(candidatesList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.candidatesListData = action.payload;
    });
    builder.addCase(candidatesList.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  }
});

export default candidatesListSlice.reducer;
