import { Icon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogContent,
  Drawer,
  FormControlLabel,
  IconButton,
  Radio,
  Switch,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicDetailsPreview from "./BasicDetailsPreview";
import SkillPreview from "./SkillPreview";
import DescriptionsPreview from "./DescriptionsPreview";
import QuestionPreview from "./QuestionPreview";
import EcnButton from "../../StyledComponents/V2/EcnButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { jobCreate } from "../../../redux/slices/JobSlices/jobCreateSlices";
import { enqueueSnackbar } from "notistack";

const Previews: React.FC<{ jobData: any; setJobData: any }> = ({
  jobData,
  setJobData
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "";
  const id = searchParams.get("id") || "";
  const tab = searchParams.get("tab") || "";

  const { firmDetailsData, errorData } = useAppSelector(
    (state: any) => state.getFirmDetailsData
  );
  const {
    jobData: successJobData,
    error,
    loading
  } = useAppSelector((state: any) => state.jobCreateData);

  const [open, setOpen] = useState<any>(false);
  const [openSuccess, setOpenSuccess] = useState<any>(false);
  const [step, setStep] = useState(0);
  const [click, setClick] = useState(false);
  const [clickDraft, setClickDraft] = useState(false);
  const [jobVisibilityOptionDialog, setJobVisibilityOptionDialog] =
    useState(false);
  const [jobVisibilityOption, setJobVisibilityOption] = useState<
    "private-job" | "both"
  >("both");

  useEffect(() => {
    if (successJobData && click) {
      if (jobVisibilityOption == "both") {
        setJobVisibilityOptionDialog(false);
        setOpenSuccess(true);
      } else {
        navigate("/jobs");
      }
      setClick(false);
    }
  }, [successJobData, click]);

  useEffect(() => {
    if (successJobData && clickDraft) {
      enqueueSnackbar("Job drafted successfully!", {
        variant: "success"
      });
      setClickDraft(false);
      navigate("/jobs");
    }
  }, [successJobData]);

  useEffect(() => {
    if (tab === "question") {
      setStep(1);
    }
  }, [tab]);

  const handleHostJob = () => {
    if (jobData?.job_questions?.length === 0) {
      setClick(true);
      dispatch(
        jobCreate({
          ...jobData,
          firm_overview: firmDetailsData?.get_again
            ? jobData.firm_overview
            : firmDetailsData?.description,
          display_picture: firmDetailsData?.get_again
            ? jobData.display_picture
            : firmDetailsData?.display_picture,
          organisations: firmDetailsData?.get_again
            ? jobData.organisations
            : [firmDetailsData?.title],
          request_public: jobVisibilityOption == "private-job" ? false : true,
          under_review: jobVisibilityOption == "private-job" ? false : true,
          draft_status: {
            draft_status: 0,
            is_draft: false
          }
        })
      );
    } else {
      setStep(1);
    }
  };

  const backHandler = () => {
    if (jobData?.job_questions?.length === 0) {
      navigate(`?view=description${id === "" ? "" : "&id=" + id}`);
    } else {
      navigate(`?view=questions${id === "" ? "" : "&id=" + id}`);
    }
  };

  const closedDrawerHandler = () => {
    setOpen(false);
  };

  const updateDataHandler = (e: any) => {
    setJobData({
      ...jobData,
      ai_scoring_enabled: e.target.checked
    });
  };
  const closedHandler = () => {
    navigate("/jobs");
  };

  const draftHandler = () => {
    setClickDraft(true);
    dispatch(
      jobCreate({
        ...jobData,
        draft_status: {
          ...jobData?.draft_status,
          is_draft: true
        }
      })
    );
  };

  return (
    <div className="previews">
      {step === 0 && (
        <>
          <div className="top">
            <div className="left">
              <Button className="back-button">
                {/* <Icon icon="material-symbols:arrow-back" />  */}
                Review details
              </Button>
            </div>
            <div className="right">
              <Button onClick={() => setOpen(true)}>Preview job</Button>
              <FormControlLabel
                value="start"
                className="switch"
                control={
                  <Switch
                    onChange={updateDataHandler}
                    checked={jobData?.ai_scoring_enabled}
                  />
                }
                label={
                  <p style={{ fontSize: "14px", fontWeight: "400" }}>
                    AI Score
                  </p>
                }
                labelPlacement="start"
              />
            </div>
          </div>
          <div className="previews-body">
            <BasicDetailsPreview jobData={jobData} />
            <SkillPreview jobData={jobData} />
            <DescriptionsPreview jobData={jobData} />
          </div>

          <div className="bottom-button">
            <div className="bottom-container">
              <div className="left">
                <EcnButton className="back" onClick={backHandler}>
                  <Icon icon="lucide:arrow-left" /> Back
                </EcnButton>

                <EcnButton
                  className="save"
                  disabled={loading}
                  onClick={draftHandler}
                >
                  <Icon icon="lucide:bookmark" /> Save draft
                </EcnButton>
              </div>
              <div>
                <EcnButton
                  className="next"
                  variant="contained"
                  onClick={() => {
                    setJobVisibilityOptionDialog(true);
                  }}
                  disabled={loading}
                >
                  <Icon
                    icon="lucide:arrow-right"
                    className="icon"
                    width={18}
                    height={18}
                  />{" "}
                  Next
                </EcnButton>
              </div>
            </div>
          </div>
        </>
      )}

      {step === 1 && (
        <>
          {" "}
          <div className="top">
            <div className="left">
              <Button className="back-button">Review details</Button>
            </div>
            <div className="right">
              <Button onClick={() => setOpen(true)}>Preview job</Button>
              <Button
                onClick={() =>
                  navigate(
                    `?view=questions${
                      id === "" ? "" : "&id=" + id
                    }&redirect=question`
                  )
                }
                className="edit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4252_1521)">
                    <path
                      d="M9.99992 3.83441L12.6666 6.50108M11.3333 2.50108C11.503 2.30043 11.7131 2.13711 11.9499 2.02153C12.1868 1.90595 12.4453 1.84063 12.709 1.82975C12.9727 1.81887 13.2358 1.86266 13.4815 1.95833C13.7272 2.054 13.9502 2.19946 14.1361 2.38542C14.3221 2.57139 14.467 2.79378 14.5616 3.03844C14.6562 3.28309 14.6985 3.54463 14.6857 3.80639C14.6728 4.06816 14.6052 4.3244 14.4872 4.55879C14.3691 4.79317 14.2032 5.00056 13.9999 5.16774L4.99992 14.1677L1.33325 15.1677L2.33325 11.5011L11.3333 2.50108Z"
                      stroke="#212121"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4252_1521">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Edit
              </Button>
            </div>
          </div>
          <QuestionPreview
            setStep={setStep}
            jobData={jobData}
            setOpenSuccess={setOpenSuccess}
          />
        </>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={closedDrawerHandler}
        className="open-job-details"
      >
        <div className="top">
          <Button onClick={closedDrawerHandler} className="back">
            <Icon icon="material-symbols:arrow-back" width={24} height={24} />{" "}
            Job preview
          </Button>
          <IconButton onClick={closedDrawerHandler}>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>

        <div className="body">
          <div className="body-top">
            <div className="left">
              <img
                src="https://ecnenterprisedev.blob.core.windows.net/ecn/66260431eb85316f2376d1d6.jpeg"
                alt=""
              />
            </div>
            <div className="right">
              <h2>{firmDetailsData?.title}</h2>
              <h1>
                {jobData?.job_title.length > 50
                  ? jobData?.job_title?.slice(0, 50) + "...."
                  : jobData?.job_title}
              </h1>
              <div className="type-city">
                <p>{jobData?.role_type}</p>
                <div className="dot"></div>
                <p>
                  {" "}
                  {jobData?.address?.country}, {jobData?.address?.state},{" "}
                  {jobData?.address?.cities[0]}
                </p>
              </div>
            </div>
          </div>

          <div className="industry-exp">
            <div className="row">
              <p className="label">Industry: </p>
              <h3>{jobData?.industries[0]}</h3>
            </div>

            <div className="row">
              <p className="label">Experience: </p>
              <h3>
                {jobData?.experience_required +
                  "-" +
                  jobData?.max_experience_required}{" "}
                years
              </h3>
            </div>
          </div>

          <div className="skill">
            <p className="label">Skills</p>
            <div className="skills">
              {jobData?.skills?.map((item: any) => <p>{item}</p>)}
            </div>
          </div>

          <div className="descriptions">
            <p className="label">Job description</p>

            <Typography variant="h6" component="p">
              <ReactMarkdown
                className="markdown-data"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {jobData?.description}
              </ReactMarkdown>
            </Typography>
          </div>

          <div className="descriptions">
            <p className="label">About the company</p>
            <Typography variant="h6" component="p">
              <ReactMarkdown
                className="markdown-data"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {jobData?.firm_overview}
              </ReactMarkdown>
            </Typography>
          </div>
        </div>
      </Drawer>
      <Dialog
        className="job-visibility-option-dialog"
        maxWidth="sm"
        open={jobVisibilityOptionDialog}
        onClose={() => {
          if (!loading) setJobVisibilityOptionDialog(false);
        }}
      >
        <DialogContent className="job-visibility-option-dialog-content">
          <div className="header">
            <h2 className="title">Final Steps</h2>
            <p className="sub-title">Select where you want to host your job</p>
          </div>
          <div className="separator" />
          <div className="options">
            <div
              onClick={() => {
                setJobVisibilityOption("private-job");
              }}
              className="option-container"
            >
              <Radio
                checked={jobVisibilityOption === "private-job"}
                onChange={() => {
                  setJobVisibilityOption("private-job");
                }}
                value="private-job-board"
                name="radio-buttons"
                inputProps={{ "aria-label": "Only private job board" }}
                style={{
                  marginLeft: -10
                }}
              />
              <div className="option-text">
                <div className="option-text-heading">
                  Show Job only on Private Job Board
                </div>
                <div className="option-text-description">
                  Private job boards will only list jobs relevant to your
                  organization, and you can have a unique customised URL to
                  promote throughout your network.
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setJobVisibilityOption("both");
              }}
              className="option-container"
            >
              <Radio
                checked={jobVisibilityOption === "both"}
                value="both"
                name="radio-buttons"
                inputProps={{
                  "aria-label": "both public and private job board"
                }}
                style={{
                  marginLeft: -10
                }}
              />
              <div className="option-text">
                <div className="option-text-heading">
                  Show Job on both Public & Private Job Board
                </div>
                <div className="option-text-description">
                  Your job will be listed on Onefinnet's network of high-quality
                  and talented individuals.
                </div>
                <div className="option-text-note">
                  Note: Your Job will be under review by us.
                </div>
              </div>
            </div>
          </div>
          <div className="cta-wrapper">
            <Button
              onClick={handleHostJob}
              className="host-job-button"
              variant="contained"
              disabled={loading}
            >
              Host Job
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSuccess}
        onClose={closedHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          timeout: 500,
          sx: {
            backgroundColor: "rgba(33, 33, 33, 0.20) !important",
            boxShadow: "none !important"
          } // Add your backdrop styles here
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50vw", // Set your width here
              maxHeight: "70vh",
              padding: "30px 35px 50px 50px",
              boxShadow: "none !important",
              borderRadius: "8px"
            }
          }
        }}
      >
        <div>
          <div style={{ textAlign: "right" }}>
            <Icon
              icon="ion:close-outline"
              width={24}
              height={24}
              color="rgba(33, 33, 33, 1)"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard")}
            />
          </div>
          <div style={{ width: "250px", height: "250px", margin: "auto" }}>
            <img
              src="https://ecn.blob.core.windows.net/ecn/images/People working from home and doing stretches.png"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>

          <h3
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "rgba(33, 33, 33, 1)",
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "15px"
            }}
          >
            Your job post is under review
          </h3>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "rgba(33, 33, 33, 1)",
              textAlign: "center",
              margin: "0px 20px"
            }}
          >
            We will review the job posting and if everything goes well we will
            make it live.
          </p>
        </div>
      </Dialog>
    </div>
  );
};

export default Previews;
