import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getEventDetails } from "../../redux/slices/EventSlices/getEventDetailsSlice";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { useQueryParams } from "../../helpers/HOC/useQueryParams";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const EventDetailsTab = ({ eventId }: { eventId: string }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);
  const [searchParams, updateQueryParams] = useQueryParams();

  useEffect(() => {
    if (eventId) {
      dispatch(getEventDetails({ eventId }));
    }
  }, [eventId]);

  let duration: number | null = null;
  if (eventDetailsData?.start_date && eventDetailsData?.end_date) {
    const start = moment(eventDetailsData.start_date);
    const end = moment(eventDetailsData.end_date);
    duration = end.diff(start, "minutes");
  }

  return (
    <div className="event-details-tab">
      <div className="hero">
        {eventDetailsData?.images?.[0] && (
          <img
            className="hero-banner"
            src={eventDetailsData?.images?.[0]}
            alt={eventDetailsData?.name}
          />
        )}
      </div>
      <div className="details-body">
        <div className="left-main-content">
          <div className="event-name">{eventDetailsData?.name}</div>
          <div className="company-name">By Elevate</div>
          <div className="event-description">
            <Typography variant="h6" component="p">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {eventDetailsData?.description || ""}
              </ReactMarkdown>
            </Typography>
          </div>
          {eventDetailsData?.speakers?.length > 0 && (
            <>
              <div className="speakers-title">Speakers</div>
              <div className="speakers-list">
                {eventDetailsData.speakers.map((speaker: any) => {
                  return (
                    <div className="speaker-card">
                      <img src={speaker.display_picture} alt={speaker.name} />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div className="right-side-content">
          <div className="right-header">
            <div className="text">
              <div> No of Registrants</div>
              <div className="registrants-number">
                {eventDetailsData?.registrations_count}
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  updateQueryParams({ tab: "registrant-details" });
                }}
                className="view-all-button"
              >
                View all
              </Button>
            </div>
          </div>
          <Button
            onClick={() => navigate(`/event/${eventId}/new-registrant`)}
            variant="outlined"
            className="add-new-registrant-button"
          >
            Add New Registrant
          </Button>
          <div className="event-info">
            <div className="info-wrapper">
              <div className="info-lable">Price</div>
              <div className="info-content">
                ${eventDetailsData?.event_price}
              </div>
            </div>
            <div className="info-wrapper">
              <div className="info-lable">Date</div>
              <div className="info-content">
                {eventDetailsData?.schedule_date &&
                  moment(eventDetailsData.schedule_date).format("D MMMM YYYY")}
              </div>
            </div>
            <div className="info-wrapper">
              <div className="info-lable">Time</div>
              <div className="info-content">
                {eventDetailsData?.schedule_date &&
                  moment(eventDetailsData.schedule_date).format("hh:mm a")}
              </div>
            </div>
            <div className="info-wrapper">
              {duration && (
                <>
                  <div className="info-lable">Duration</div>
                  <div className="info-content">
                    {duration % 60 == 0
                      ? `${Math.floor(duration / 60)} Hour${
                          Math.floor(duration / 60) > 1 ? "s" : ""
                        }`
                      : duration > 60
                        ? `${Math.floor(duration / 60)} Hour${
                            Math.floor(duration / 60) > 1 ? "s" : ""
                          } and ${duration % 60} Minutes`
                        : `${duration} Minutes`}
                  </div>
                </>
              )}
            </div>
            <div className="info-wrapper">
              <div className="info-lable">Location</div>
              <div className="info-content">
                {eventDetailsData?.address?.city}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsTab;
