import React, { useEffect, useState } from "react";
import EcnInput from "../../StyledComponents/EcnInput";
import EcnButton from "../../StyledComponents/EcnButton";
import {
  Dialog,
  DialogContent,
  InputAdornment,
  Menu,
  MenuItem
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { useQueryParams } from "../../../helpers/HOC/useQueryParams";

const RegistrantSearchFilter = ({
  paymentView = false,
  onExportPage,
  onExportAll
}: {
  paymentView?: boolean;
  onExportPage?: () => void;
  onExportAll?: () => void;
}) => {
  const navigate = useNavigate();
  const [_, updateQueryParams] = useQueryParams();
  const { eventId } = useParams();

  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);

  const [search, setSearch] = useState<string>("");
  const [confirmIssue, setConfirmIssue] = useState(false);
  const [confirmTemplate, setConfirmTemplate] = useState(false);

  const [anchorExportMenu, setAnchorExportMenu] =
    React.useState<null | HTMLElement>(null);
  const openExportMenu = Boolean(anchorExportMenu);

  useEffect(() => {
    const tid = setTimeout(() => {
      updateQueryParams({ q: search });
    }, 300);
    return () => clearTimeout(tid);
  }, [search]);

  const exportClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorExportMenu(event.currentTarget);
  };
  const handleExportMenuClose = () => {
    setAnchorExportMenu(null);
  };

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const closeConfirmIssueHandler = () => {
    setConfirmIssue(false);
  };

  const closeConfirmTemplateHandler = () => {
    setConfirmTemplate(false);
  };

  const certificateRedirectHandler = () => {
    navigate(`/event/${eventId}/create-certificate`);
  };

  const issueCertificateHandler = () => {
    if (
      eventDetailsData?.certificate_settings?.title &&
      eventDetailsData?.certificate_settings?.heading
    ) {
      setConfirmIssue(true);
    } else {
      setConfirmTemplate(true);
    }
  };
  return (
    <div className="registrant-search-filter">
      <EcnInput
        value={search}
        onChange={searchChangeHandler}
        sx={{ width: "300px" }}
        className="ecn-input-v2 search-input"
        placeholder="Search Registrants"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon="lucide:search" height={24} width={24} />
            </InputAdornment>
          )
        }}
      />
      <div className="btn-group">
        {paymentView ? (
          <>
            <EcnButton
              onClick={issueCertificateHandler}
              variant="outlined"
              className="btn-outlined-v2"
            >
              Issue Certificates
            </EcnButton>
          </>
        ) : (
          <EcnButton
            onClick={exportClickHandler}
            variant="outlined"
            className="btn-outlined-v2"
          >
            Export
          </EcnButton>
        )}
        <EcnButton
          onClick={certificateRedirectHandler}
          variant="outlined"
          className="btn-outlined-v2"
        >
          Edit Certificate
        </EcnButton>

        <EcnButton
          onClick={() => navigate(`/event/${eventId}/new-registrant`)}
          variant="outlined"
          className="btn-outlined-v2"
        >
          New Registrants
        </EcnButton>
      </div>

      <Dialog
        open={confirmIssue}
        onClose={closeConfirmIssueHandler}
        PaperProps={{ style: { borderRadius: "8px" } }}
      >
        <DialogContent style={{ padding: "0" }}>
          <div>
            <div style={{ padding: "20px" }}>
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ maxWidth: "173px", marginBottom: "34px" }}
                  src="https://s1.ecnstatic.com/ecn/images/certificate.png"
                  alt=""
                />
              </div>
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                  marginBottom: "41px",
                  maxWidth: "80%",
                  marginInline: "auto"
                }}
              >
                Certificates will be only issued to participants who have been
                marked as paid.{" "}
              </h2>
            </div>
            <div
              style={{ background: "rgba(33, 33, 33, 0.05)", padding: "20px" }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                Do you wish to continue?
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 30px",
                  gap: "36px"
                }}
              >
                <EcnButton
                  variant="outlined"
                  className="btn-outlined-v2"
                  onClick={closeConfirmIssueHandler}
                  style={{
                    minWidth: "230px",
                    fontSize: "18px",
                    fontWeight: "600",
                    padding: "6px"
                  }}
                >
                  Cancel
                </EcnButton>
                <EcnButton
                  variant="contained"
                  className="btn-contained-v2"
                  onClick={() => {}}
                  style={{
                    minWidth: "230px",
                    fontSize: "18px",
                    fontWeight: "600"
                  }}
                >
                  Yes
                </EcnButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmTemplate}
        maxWidth="sm"
        onClose={() => setConfirmTemplate(false)}
        PaperProps={{ style: { borderRadius: "8px" } }}
      >
        <DialogContent>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#212121",
              textAlign: "center",
              margin: "20px 30px"
            }}
          >
            It seems you haven't created a certificate yet. Please create a
            template before we proceed.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 30px",
              gap: "36px"
            }}
          >
            <EcnButton
              variant="outlined"
              className="btn-outlined-v2"
              onClick={closeConfirmTemplateHandler}
              style={{
                minWidth: "230px",
                fontSize: "18px",
                fontWeight: "600",
                padding: "6px"
              }}
            >
              Cancel
            </EcnButton>
            <EcnButton
              variant="contained"
              className="btn-contained-v2"
              onClick={certificateRedirectHandler}
              style={{ minWidth: "230px", fontSize: "18px", fontWeight: "600" }}
            >
              Create Certificate
            </EcnButton>
          </div>
        </DialogContent>
      </Dialog>
      <Menu
        id="registrants-export-menu"
        anchorEl={anchorExportMenu}
        open={openExportMenu}
        onClose={handleExportMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem
          onClick={() => {
            onExportPage?.();
            handleExportMenuClose();
          }}
        >
          Export page
        </MenuItem>
        <MenuItem
          onClick={() => {
            onExportAll?.();
            handleExportMenuClose();
          }}
        >
          Export All
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RegistrantSearchFilter;
