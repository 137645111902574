import getEventAttendeeDetailSlice from "./getEventAttendeeDetailSlice";
import getEventAttendeesSlice from "./getEventAttendeesSlice";
import getEventListSlice from "./getEventListSlice";
import getRequestListSlice from "./getRequestListSlice";
import getEventDetailsSlice from "./getEventDetailsSlice";
import updatePaymentStatusSlice from "./updatePaymentStatus";
import deleteEventSlice from "./deleteEventSlice";
import createCertificateSlice from "./createCertificateSlice";
import registerPrivateUserSlice from "./registerPrivateUserSlice";
import bulkCertificateIssueSlice from "./bulkCertificateIssueSlice";

export const allEventsSlices = {
  getAllEvents: getEventListSlice,
  getEventAttendees: getEventAttendeesSlice,
  getEventAttendeeDetail: getEventAttendeeDetailSlice,
  getRequests: getRequestListSlice,
  getEventDetails: getEventDetailsSlice,
  updatePaymentStatus: updatePaymentStatusSlice,
  deleteEvent: deleteEventSlice,
  createCertificate: createCertificateSlice,
  registerPrivateUser: registerPrivateUserSlice,
  bulkCertificateIssue: bulkCertificateIssueSlice
};
