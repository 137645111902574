import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import layoutTwo from "../components/layout/layoutTwo";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getJobList } from "../redux/slices/CandidatesSlices/jobListSlice";

import TableBody from "../components/JobCreations/Applicants/TableBody";
import { candidatesList } from "../redux/slices/CandidatesSlices/candidatesListSlice";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import moment from "moment";
import Breadcrumb from "../components/StyledComponents/V2/Breadcrumb";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";

const JobApplicants = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id");

  const { getJobsListData } = useAppSelector((state: any) => state.jobsList);

  useEffect(() => {
    dispatch(getJobList({ skip: 0, limit: 10000 }));
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(candidatesList({ id: id, st: -1 }));
    }
  }, [id]);

  const selectJobHandler = (e: any) => {
    navigate(`?id=${e.target.value}`);
  };

  return (
    <div className="job-applicants">
      <EcnBreadCrumbs>
        <p>Jobs</p>
        <p
          onClick={() => navigate(`/jobs?view=details&id=${id}`)}
          style={{ cursor: "pointer" }}
        >
          {getJobsListData?.result?.find((item: any) => item._id === id)?.job_title}
        </p>
        <p>Candidates</p>
      </EcnBreadCrumbs>

      <h1 className="title">Candidates</h1>

      <div className="row">
        <p>Select job</p>

        <div className="select">
          <FormControl>
            <Select
              size="small"
              variant="standard"
              displayEmpty={true}
              disableUnderline
              value={id}
              input={
                <OutlinedInput
                  sx={{
                    border: "1.5px solid rgba(33, 33, 33, 0.20)",
                    "& fieldset": {
                      border: "1.5px solid rgba(33, 33, 33, 0.20) !important",
                    },
                    "&:hover fieldset": {
                      border: "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                    },
                    borderRadius: "8px",
                  }}
                />
              }
              onChange={selectJobHandler}
              IconComponent={KeyboardArrowDown}
              renderValue={(selected) => {
                if (selected === "") {
                  return <div className="placeholder">Select Status</div>;
                }
                const selectedStatus = getJobsListData?.result?.find(
                  (item: any) => item._id === id
                );

                return (
                  <div>
                    {selectedStatus ? selectedStatus?.job_title : ""}{" "}
                    {/* Render the text property */}
                  </div>
                );
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                    boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                    borderRadius: "6px",
                    marginTop: "10px",
                    backgroundColor: "#FFF",
                    padding: "10px 10px",
                  },
                },
              }}
            >
              {getJobsListData?.result?.map((item: any) => (
                <MenuItem
                  value={item?._id}
                  sx={
                    item?._id === id
                      ? {
                          background: "rgba(0, 52, 187, 0.08)!important",
                          borderRadius: "9px",
                          marginBottom: "10px !important",
                          fontSize: "14px !important",
                          fontWeight: "400",
                          color: "#0034bb !important",
                          padding: "10px 15px",
                        }
                      : {
                          background: "#fff !important",
                          marginBottom: "10px !important",
                          fontSize: "14px !important",
                          color: "rgba(33,33,33,.7)",
                          fontWeight: "400",
                          padding: "10px 15px",
                          "&:hover": {
                            backgroundColor: "rgba(0, 52, 187, 0.08) !important",
                            color: "rgba(0, 52, 187, 1)",
                            borderRadius: "9px",
                          },
                        }
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p>
                      {" "}
                      {item?.job_title?.length > 50
                        ? item?.job_title?.slice(0, 50) + "..."
                        : item?.job_title}
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "14",
                        color: "rgba(33, 33, 33, 0.70)",
                      }}
                    >
                      {" "}
                      {moment.unix(item?.createdAt).format("ll")}{" "}
                    </p>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <TableBody />
    </div>
  );
};

export default layoutTwo(JobApplicants);
